<template>
  <b-button variant="primary" @click="$root.$emit('update-matrix')">
    Save
  </b-button>
</template>

<script>
export default {
  name: 'MatrixTool',
}
</script>

<style scoped>

</style>
